<script setup>
import { WdsButtonPrimary, WdsSetup } from '@wds/components';

const props = defineProps({ error: Object });

const runtimeConfig = useRuntimeConfig();
const envData = runtimeConfig?.public;

const message = computed(() => String(props.error?.message || ''));
const is404 = computed(() => props.error?.statusCode === 404 || message.value?.includes('404'));
const isDev = ['dev', 'test'].includes(envData?.RELEASE_ENV);

function handleError() {
  return clearError({ redirect: '/' });
}
</script>

<template>
  <WdsSetup />
  <div
    v-if="isDev"
    class="error"
  >
    <div class="error-title">
      {{ is404 ? '404 Not Found' : '500 Error' }}
    </div>
    <pre>{{ error }}</pre>
    <WdsButtonPrimary
      class="error-btn"
      text="Go Back"
      @click="handleError"
    />
  </div>
  <NuxtLayout :name="is404 ? '404' : '500'">
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.error {
  background: $wds-color-orange-lighter;
  color: $wds-color-black;
  padding: $wds-spacing-l;
  border-bottom: 1px solid $wds-color-orange;

  .error-title {
    @include title-3-bld;

    margin-bottom: $wds-spacing-m;
  }

  pre {
    @include body-3-reg;

    text-wrap: pretty;
    margin-bottom: $wds-spacing-m;
  }
}

.error-btn {
  display: flex;
}
</style>
