import { addDays, addMonths, differenceInCalendarDays, format, formatISO, getDay, intlFormat, isWithinInterval, parseISO, startOfDay } from 'date-fns';

export function useDateTime() {
  const today = startOfDay(Date.now());
  const tomorrow = addDays(today, 1);

  const formatToObject = (dt) => {
    return {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate(),
    };
  };

  const formatFromObjectToDate = (dt) => {
    return new Date(dt.year, dt.month - 1, dt.day);
  };

  const formatToFlat = (dt) => {
    return format(formatFromObjectToDate(dt), 'yyyy-MM-dd');
  };

  const formatFromFlatToObject = (dt) => {
    return formatToObject(new Date(dt));
  };

  const convertToFormat = (dt, format) => {
    switch (format) {
      case 'object':
        return formatToObject(dt);
      case 'flat':
        return formatToFlat(formatToObject(dt));
      default:
        return dt;
    }
  };

  const getLeadTime = (dt) => {
    const arrivalDateISO = parseISO(dt);
    return differenceInCalendarDays(arrivalDateISO, today);
  };

  const getDiffInDays = (startDate, endDate) => {
    startDate = startDate?.year
      ? formatToFlat(startDate)
      : startDate instanceof Date
        ? formatISO(startDate)
        : startDate;
    endDate = endDate?.year ? formatToFlat(endDate) : endDate instanceof Date ? formatISO(endDate) : endDate;
    const startDateObj = parseISO(startDate);
    const endDateObj = parseISO(endDate);
    return differenceInCalendarDays(endDateObj, startDateObj);
  };

  const isDateWithinInterval = (dt, periodStart, periodEnd) => {
    const compareDate = dt || today;
    return isWithinInterval(compareDate, {
      start: periodStart,
      end: periodEnd,
    });
  };

  const addDaysToDate = (dt, numOfDays) => {
    const startDate = dt ? new Date(dt.year, dt.month - 1, dt.day) : today;
    return formatToObject(addDays(startDate, numOfDays));
  };

  const addMonthsToDate = (dt, numOfMonths, returnFormat = 'object') => {
    const startDate = dt ? new Date(dt.year, dt.month - 1, dt.day) : today;
    const targetDate = addMonths(startDate, numOfMonths);
    return convertToFormat(targetDate, returnFormat);
  };

  const formatToHumanReadableShortDate = (dt, lang, format = null) => {
    let realDate = null;

    if (dt?.year) {
      realDate = new Date(dt.year, dt.month - 1, dt.day);
    } else {
      realDate = new Date(dt);
    }

    const localDayString = intlFormat(realDate, { day: 'numeric' }, { locale: lang });
    const localShortMonthString = intlFormat(realDate, { month: 'short' }, { locale: lang });
    const localYearString = intlFormat(realDate, { year: 'numeric' }, { locale: lang });

    switch (format) {
      case 'month|year':
        return `${localShortMonthString} ${localYearString}`;
      default:
        return `${localDayString} ${localShortMonthString}`;
    }
  };

  const getDayOfWeek = (date) => {
    return getDay(date);
  };

  return {
    today: formatToObject(today),
    tomorrow: formatToObject(tomorrow),
    addDaysToDate,
    addMonthsToDate,
    getLeadTime,
    getDiffInDays,
    formatToObject,
    formatToFlat,
    formatToHumanReadableShortDate,
    formatFromFlatToObject,
    formatFromObjectToDate,
    getDayOfWeek,
    isDateWithinInterval,
  };
}
