import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/build/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/build/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/build/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ from "/build/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/build/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _01_cookie_consent_client_9UTgexQRjg6e_jHIw8KGOqNZefyHf8_koQJhU_dmSv8 from "/build/src/plugins/01.cookie-consent.client.js";
import _01_error_handler_uzPn98gegXt_zlSGDhkzP_1jweakA89LExlor6Foq4M from "/build/src/plugins/01.error-handler.js";
import _02_sentry_client_L7aVoTugzp3Ms6B20Gh0DzuYwkcM4hytzYpbQXH1qhg from "/build/src/plugins/02.sentry.client.js";
import _04_urls_olBHItrLSlJ7ltrLVkjPbncc_MSiNz1QK7yrBY2n7DY from "/build/src/plugins/04.urls.js";
import _05_optimizely_6902BCmQM5sS01CkMxbRnVB_7sB6UiqD9CUl8bqmZCg from "/build/src/plugins/05.optimizely.js";
import _06_api_0M0o6WdLxpsdJ_sqV7HyLs2_2v8Gvvp_Xy1mKtwh4MM from "/build/src/plugins/06.api.js";
import _97_google_tag_manager_client_7V9I6GLX8X3XFaOe_5_Xeu8jCWrK5fi6sTW_tV5RUyo from "/build/src/plugins/97.google-tag-manager.client.js";
import _98_segment_client_GLWtbnLIEbLCiRYVDofNuZXJ1jQ1RyHXjpMwiHVuZZA from "/build/src/plugins/98.segment.client.js";
import _99_tracking_client_AhFScOeY3Ea5A4aevCnwefhG87cYx49TPi3iTKW1Pk0 from "/build/src/plugins/99.tracking.client.js";
import mq_6gKoMNB3FxCwmxrkn3iu32dpD52pVG70LuW9tBY0nWw from "/build/src/plugins/mq.js";
import vue3_touch_events_JiaH7JFTHlcLsd5rSAqaKKE9iMQnesmkt5XUKhxS5c0 from "/build/src/plugins/vue3-touch-events.js";
export default [
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  _01_cookie_consent_client_9UTgexQRjg6e_jHIw8KGOqNZefyHf8_koQJhU_dmSv8,
  _01_error_handler_uzPn98gegXt_zlSGDhkzP_1jweakA89LExlor6Foq4M,
  _02_sentry_client_L7aVoTugzp3Ms6B20Gh0DzuYwkcM4hytzYpbQXH1qhg,
  _04_urls_olBHItrLSlJ7ltrLVkjPbncc_MSiNz1QK7yrBY2n7DY,
  _05_optimizely_6902BCmQM5sS01CkMxbRnVB_7sB6UiqD9CUl8bqmZCg,
  _06_api_0M0o6WdLxpsdJ_sqV7HyLs2_2v8Gvvp_Xy1mKtwh4MM,
  _97_google_tag_manager_client_7V9I6GLX8X3XFaOe_5_Xeu8jCWrK5fi6sTW_tV5RUyo,
  _98_segment_client_GLWtbnLIEbLCiRYVDofNuZXJ1jQ1RyHXjpMwiHVuZZA,
  _99_tracking_client_AhFScOeY3Ea5A4aevCnwefhG87cYx49TPi3iTKW1Pk0,
  mq_6gKoMNB3FxCwmxrkn3iu32dpD52pVG70LuW9tBY0nWw,
  vue3_touch_events_JiaH7JFTHlcLsd5rSAqaKKE9iMQnesmkt5XUKhxS5c0
]