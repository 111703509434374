import { useJwt } from '@vueuse/integrations/useJwt';

const LOGIN_TOKEN_COOKIE = 'hw_id_token';
const LOGIN_ACCESS_TOKEN_COOKIE = 'hw_access_token';
const LOGIN_REFRESH_TOKEN_COOKIE = 'hw_refresh_token';
const LOGIN_EXPIRES_IN_COOKIE = 'hw_expires_in';
const USER_PLACEHOLDER_EMAIL = 'hw_placeholder_email';
const FACEBOOK_PICTURE = 'https://hostelworld.com/uploaded_picture';

export function useAccount() {
  const nuxtApp = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;
  const { $api } = nuxtApp;
  const { sendUsersUpdateEvent } = useApiIterable();
  const { useImage } = useImages();

  const defaultQuery = {};

  const defaultHeaders = {};

  const defaultParams = {};

  // ### COOKIES
  const userLoginCookieOptions = {
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    secure: true,
    sameSite: 'lax',
    watch: true,
  };
  const userLoginToken = useCookie(LOGIN_TOKEN_COOKIE, userLoginCookieOptions);
  const userLoginIdToken = useCookie(LOGIN_TOKEN_COOKIE, userLoginCookieOptions);
  const userLoginAccessToken = useCookie(LOGIN_ACCESS_TOKEN_COOKIE, userLoginCookieOptions);
  const userLoginRefreshToken = useCookie(LOGIN_REFRESH_TOKEN_COOKIE, userLoginCookieOptions);
  const userLoginExpiresInToken = useCookie(LOGIN_EXPIRES_IN_COOKIE, userLoginCookieOptions);
  const userLoginPlaceholderEmail = useCookie(USER_PLACEHOLDER_EMAIL, {
    ...userLoginCookieOptions,
    maxAge: 34560000,
    sameSite: 'strict',
  });

  // ### COMPOSABLES
  const { useUrl } = useUrls();

  // ### STATIC VALUES
  const loggedInUser = ref(null);
  const translation = nuxtApp?.$i18n;

  // ### COMPUTED / GETTERS
  const hasLoggedInUser = computed(() => {
    return !!toValue(loggedInUser)?.email;
  });

  const getLoggedInUser = computed(() => {
    return hasLoggedInUser ? toValue(loggedInUser) : null;
  });

  const getLoggedInMenuItems = computed(() => {
    if (!hasLoggedInUser) {
      return [];
    }

    return {
      account: {
        label: translation?.t('t_ACCOUNT'),
        href: useUrl.getMyAccountPageUrlDynamic('account'),
        icon: 'home',
      },
      details: {
        label: translation?.t('t_EDITDETAILS'),
        href: useUrl.getMyAccountPageUrlDynamic('account/details'),
        icon: 'user-line',
      },
      wallet: {
        label: translation?.t('t_WALLET'),
        href: useUrl.getMyAccountPageUrlDynamic('account/wallet'),
        icon: 'wallet',
      },
      trips: {
        label: translation?.t('t_MYTRIPS'),
        href: useUrl.getMyAccountPageUrlDynamic('account/trips'),
        icon: 'map',
      },
      help: {
        label: translation?.t('t_HELP'),
        href: translation?.t('t_CONTACTUSURL'),
        icon: 'question',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      logout: {
        label: translation?.t('t_LOGOUT'),
        icon: 'check-out',
      },
    };
  });

  // ### METHODS / ACTIONS
  const setLoginTokensIntoCookies = function (idToken, accessToken, refreshToken, expires) {
    userLoginIdToken.value = idToken;
    userLoginAccessToken.value = accessToken;
    userLoginRefreshToken.value = refreshToken;
    userLoginExpiresInToken.value = expires;
  };

  const setLoggedInUser = async function () {
    const userEncodedJwt = toValue(userLoginToken);

    if (userEncodedJwt) {
      const userData = useJwt(userEncodedJwt);
      const userInfo = toValue(userData.payload);
      let url = userInfo[FACEBOOK_PICTURE] || userInfo?.picture || '';
      loggedInUser.value = {
        name: userInfo?.name,
        email: userInfo?.email,
        picture: url,
        token: userEncodedJwt,
        refreshToken: toValue(userLoginRefreshToken.value),
      };
      // Perform image validation asynchronously
      if (import.meta.client && url) {
        const isValid = await useImage.isImageUrlValid(url);
        url = isValid ? url : '';
        // Update the photo property after validation
        loggedInUser.value.picture = url;
      }
    }
  };

  const refreshUserToken = async function () {
    if (toValue(hasLoggedInUser)) {
      const userInfo = toValue(loggedInUser);
      const userRefreshToken = userInfo?.refreshToken;

      if (userRefreshToken) {
        const callKey = `oAuthRefreshToken${userInfo.email}${userRefreshToken}`;
        const endpoint = 'oauth/token';

        const refreshTokenHeaders = {
          ...defaultHeaders,
          ...{
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const refreshTokenExtraOptions = {
          method: 'POST',
          body: new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: envData.AUTH0_CLIENTID,
            refresh_token: userRefreshToken,
          }).toString(),
        };

        const refreshTokenResult = await $api.authApi(
          callKey,
          endpoint,
          refreshTokenHeaders,
          defaultQuery,
          defaultParams,
          refreshTokenExtraOptions,
        );

        if (!refreshTokenResult?.error) {
          setLoginTokensIntoCookies(
            refreshTokenResult?.id_token,
            refreshTokenResult?.access_token,
            refreshTokenResult?.refresh_token,
            refreshTokenResult?.expires_in,
          );
        }
      }
    }
  };

  const logout = function () {
    setLoginTokensIntoCookies(null, null, null, null);

    const genPlaceholderEmail = `${crypto.randomUUID()}.pwa@placeholder.email`;

    userLoginPlaceholderEmail.value = genPlaceholderEmail;

    sendUsersUpdateEvent({ email: genPlaceholderEmail });

    const nationalityStorage = useLocalStorage('nationality');
    nationalityStorage.value = null;
  };

  // ### TRY TO FETCH THE LOGGED-IN USER INFORMATION
  setLoggedInUser();

  return {
    hasLoggedInUser,
    getLoggedInUser,
    getLoggedInMenuItems,
    logout,
    refreshUserToken,
  };
}
