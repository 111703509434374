export function useImages() {
  const getPlaceholderImage = () => {
    return '/image-placeholder.svg';
  };

  const addProtocol = (url, protocol = 'https') => {
    if (new RegExp(`^${protocol}`).test(url)) {
      return url;
    }

    return `${protocol.toLowerCase()}://${url?.trimStart()}`;
  };

  const getImage = (imageUrl) => {
    if (!imageUrl) {
      return '';
    }
    return addProtocol(imageUrl);
  };

  const getOptimizedImageUrl = (image, opts) => {
    const CLOUDINARY_IDENTIFIER = 'f_auto,q_auto';
    let str = '';

    const imageUrl = getImage(image);

    if (!imageUrl.includes(CLOUDINARY_IDENTIFIER) || !opts) {
      return imageUrl;
    }

    const imageUrlObj = new URL(imageUrl);
    const imageUrlParts = imageUrlObj?.pathname?.split('/');
    const cloudinarySection = Object.keys(imageUrlParts).find((key) =>
      imageUrlParts[key]?.includes(CLOUDINARY_IDENTIFIER),
    );

    // Cloudinary image format
    str += opts?.format ? `f_${opts.format},` : 'f_auto,';
    str += opts?.quality ? `q_${opts.quality}` : 'q_auto';

    if (opts?.size) {
      str += `,t_${opts.size}`;
    } else {
      str += opts?.width ? `,w_${opts.width}` : '';
      str += opts?.height ? `,h_${opts.height}` : '';
      str += opts?.height && opts?.cropSettings ? opts.cropSettings : ',c_limit';
    }

    str += opts?.sharpen ? ',e_sharpen' : '';
    str += opts?.improve ? ',e_improve' : '';
    str += opts?.vibrance ? ',e_vibrance:60' : '';

    imageUrlParts[cloudinarySection] = str;

    return imageUrlObj?.origin + imageUrlParts.join('/');
  };

  // Helper function to check if an image URL is valid
  const isImageUrlValid = (url) => {
    return new Promise((resolve) => {
      const img = new Image(url);
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  return {
    useImage: {
      getPlaceholderImage,
      getImage,
      getOptimizedImageUrl,
      isImageUrlValid,
    },
  };
}
