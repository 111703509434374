import validate from "/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import currency_45global from "/build/src/middleware/currency.global.js";
import redirects_45global from "/build/src/middleware/redirects.global.js";
export const globalMiddleware = [
  validate,
  currency_45global,
  redirects_45global
]
export const namedMiddleware = {
  "validate-area": () => import("/build/src/middleware/validate-area.js"),
  "validate-city": () => import("/build/src/middleware/validate-city.js"),
  "validate-continent": () => import("/build/src/middleware/validate-continent.js"),
  "validate-country": () => import("/build/src/middleware/validate-country.js"),
  "validate-district": () => import("/build/src/middleware/validate-district.js"),
  "validate-property": () => import("/build/src/middleware/validate-property.js"),
  "validate-region": () => import("/build/src/middleware/validate-region.js"),
  "validate-worldwide": () => import("/build/src/middleware/validate-worldwide.js")
}